<template>
  <div ref="scroll-y-container" class="relative">
    <Navigation class="!bg-transparent !shadow-none" />
    <div
      ref="container"
      class="scrollbar-none fixed inset-x-0 top-0 h-screen w-screen overflow-auto opacity-0 transition-opacity"
      :class="{ 'opacity-100': imageIsLoaded }"
    >
      <div ref="overflow-container" class="relative h-full overflow-hidden">
        <!-- BACKGROUND IMAGE -->
        <source
          srcset="~/assets/images/beadvanced-berner-vernetzungsplattform.webp"
          type="image/webp"
        />
        <source
          srcset="~/assets/images/beadvanced-berner-vernetzungsplattform.jpg"
          type="image/jpg"
        />

        <img
          ref="backgroundImage"
          class="absolute top-0 h-screen w-auto"
          src="~/assets/images/beadvanced-berner-vernetzungsplattform.jpg"
          alt="Illustration Berner Innovations-Landschaft"
        />

        <img
          class="pointer-events-none absolute bottom-0 left-0 z-20"
          src="~/assets/images/plants-foreground.png"
          alt="Illustration Berner Innovations-Landschaft-Vordergrund"
        />

        <!-- LOTTIE ANIMATION -->
        <ClientOnly>
          <Vue3Lottie
            animation-link="/lottie/home.json"
            class="pointer-events-none absolute inset-0 left-[35.725vh] h-full w-full"
          />
        </ClientOnly>

        <!-- HERO -->
        <HeroHome
          class="absolute -bottom-5 -left-14 z-10"
          :page="page"
          @scroll-to="scrollTo()"
        />

        <!-- BUTTONS -->
        <div class="pointer-events-none absolute inset-0 top-32 z-30">
          <NuxtLink
            v-for="(entry, index) in store.homeButtons?.entries"
            :key="entry.slug"
            class="button-secondary pointer-events-auto absolute"
            :class="getPosition(index)"
            :to="entry.url"
          >
            <span
              class="text text-base font-semibold tracking-wider text-white"
              >{{ entry.title }}</span
            >
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from '~/store'
import debounce from 'lodash/debounce'
export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      imageIsLoaded: false,
      imageNaturalWidth: 0,
      imageNaturalHeight: 0,
    }
  },

  computed: {
    imageRatio() {
      return this.imageNaturalWidth / this.imageNaturalHeight
    },

    store() {
      return store || {}
    },
  },

  watch: {
    imageRatio() {
      this.resizeOverflowContainer()
    },
  },

  mounted() {
    // initial default aspect ratio
    this.$refs['overflow-container'].style.width =
      this.$refs['container'].clientHeight * 7.2

    // if the images comes form the cache
    if (this.$refs['backgroundImage'].complete) {
      this.getImageNaturalSize()
    } else {
      this.$refs['backgroundImage'].addEventListener(
        'load',
        this.getImageNaturalSize
      )
    }

    this.onResize = debounce(this.onResize, 200)

    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    getImageNaturalSize() {
      this.imageIsLoaded = true
      this.imageNaturalWidth = this.$refs['backgroundImage'].naturalWidth
      this.imageNaturalHeight = this.$refs['backgroundImage'].naturalHeight
    },

    onResize() {
      this.resizeOverflowContainer()
    },

    async resizeOverflowContainer() {
      if (!this.$refs['container']) {
        console.warn('Resize Overflow Container: container not available')
        return
      }

      const clientHeight = await this.checkForElement(this.$refs['container'])
      const clientWidth = this.$refs['container'].clientWidth

      const diffWidthHeight = clientWidth - clientHeight

      this.$refs['overflow-container'].style.width =
        clientHeight * this.imageRatio + 'px'

      // it is necessary to subtract the with and height difference to have the same scroll lengths
      this.$refs['scroll-y-container'].style.height =
        clientHeight * this.imageRatio - diffWidthHeight + 'px'
    },

    checkForElement(element, counter = 0) {
      /**
       * Unfortunately, objectFit on getComputedStyle of an element is not available for an unknown time in chrome.
       * This is a workaround to wait for the objectFit to be available.
       *
       * The function works by using a combination of `requestAnimationFrame` and a `Promise` to repeatedly check the value of the `display` property from the element's computed styles until it is no longer an empty string or 100 iterations have passed.
       */
      return new Promise((resolve) => {
        if (counter === 100) {
          // thinking: 100/60fps = 1.66 seconds
          console.error(
            'display of app-image never became non-empty. Tell Tom or Manu or Kyle or Nattha to fix this.'
          )
          return resolve('')
        }

        requestAnimationFrame(() => {
          try {
            const display =
              getComputedStyle(element).getPropertyValue('display')

            if (display) {
              resolve(element.clientHeight)
            } else {
              this.checkForElement(element, ++counter).then(resolve)
            }
          } catch (error) {
            console.error('Error accessing computed style:', error)
            resolve('')
          }
        })
      })
    },

    onScroll() {
      if (!this.$refs['container']) return
      let { top } = this.$refs['scroll-y-container'].getBoundingClientRect()

      this.$refs['container'].scrollLeft = -top
    },

    scrollTo() {
      const scroll = this.$refs['overflow-container'].clientWidth * 0.13

      window.scrollBy({
        top: scroll,
        left: 0,
        behavior: 'smooth',
      })
    },

    getPosition(index) {
      switch (index) {
        case 0:
          return 'left-[14%] top-[80%] button-first'
        case 1:
          return 'left-[24%] top-[34%] sm:top-[38%] 2xl:top-[52%]'
        case 2:
          return 'left-[37.5%] top-[25%] sm:top-[30%] 2xl:top-[40%]'
        case 3:
          return 'left-[44%] top-[15%] sm:top-[20%] 2xl:top-[30%]'
        case 4:
          return 'left-[61%] top-[-10%] sm:top-[-5%] 2xl:top-[14%]'
        case 5:
          return 'left-[73.1%] top-[30%] sm:top-[40%] 2xl:top-[50%]'
        case 6:
          return 'left-[84.5%] top-[89%]'
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.button-first {
  @media (max-height: 880px) and (min-width: 1536px) {
    left: 55rem;
  }
  @media (max-height: 770px) and (max-width: 1536px) {
    left: 41rem;
  }
}
</style>
